import React from "react"

import * as styles from "./TweetCard.module.css"

import twitterIcon from "../images/twitter.svg"
import dagoIcon from "../images/dago.png"
import likesIcon from "../images/likes.png"
import retweetsIcon from "../images/retweet.png"

const TweetCard = ({ date, id, performance }) => {
  const displayDate = `${new Date(date).toLocaleString("default", {
    month: "long",
  })} ${new Date(date).getDate()}, ${new Date(date).getFullYear()}`

  const { likes, retweets } = performance

  return (
    <div className={styles.card}>
      <a href={`https://twitter.com/dagorenouf/status/${id}`} target="_blank">
        <div className={styles.head}>
          <p>Original Post</p>
          <img src={twitterIcon} alt="Twitter" height={19} width={24} />
        </div>
        <div className={styles.body}>
          <div className={styles.profile}>
            <img src={dagoIcon} height={45} width={45} />
            <div className={styles.details}>
              <p className={styles.name}>Dagobert Renouf</p>
              <p>@dagorenouf</p>
            </div>
          </div>
          <div className={styles.tweetDetails}>
            <div className={styles.date}>
              <p>{displayDate}</p>
            </div>
            <div className={styles.stats}>
              <div>
                <img src={likesIcon} alt="Likes" height={14} width={14} />
                <p>{likes}</p>
              </div>
              {/* <div>
                                <img src={retweetsIcon} alt="Retweets" height={14} width={14} />
                                <p>{retweets}</p>
                            </div> */}
            </div>
          </div>
        </div>
      </a>
    </div>
  )
}

export default TweetCard
