import React from "react"
import { Tweet } from "react-twitter-widgets"
import { Plock } from "react-plock"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

import Header from "../components/Header"
import Footer from "../components/Footer"
import MemeCard from "../components/MemeCard"
import TweetCard from "../components/TweetCard"

import * as styles from "./MemePage.module.css"

const MemePage = ({ pageContext }) => {
  const breakpoints = [
    { size: 640, columns: 1 },
    { size: 768, columns: 2 },
    { size: 1024, columns: 3 },
  ]

  function convertThemeToSlug(theme) {
    const allowedChars =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"
    let slug = ""
    for (let i = 0; i < theme.length; i++) {
      if (allowedChars.includes(theme[i])) {
        slug += theme[i].toLowerCase()
      } else if (theme[i] === " ") {
        slug += "-"
      }
    }
    return slug
  }

  return (
    <>
      <Header />
      <Helmet>
        <title>Startup meme {pageContext.caption}</title>
        <meta name="description" content={pageContext.caption} />
      </Helmet>
      <section className={styles.main}>
        <div className={styles.container}>
          <div className={styles.media}>
            {pageContext.type === "image" && (
              <img src={pageContext.media} alt="" loading="lazy" />
            )}
            {pageContext.type === "video" && (
              <video
                src={pageContext.media}
                loop
                poster={pageContext.poster ? pageContext.poster : undefined}
                preload="metadata"
                loop
                muted
                controls
              />
            )}
          </div>
          <div className={styles.tweet}>
            <p className={styles.caption}>{pageContext.caption}</p>
            {pageContext.themes !== undefined && (
              <div className={styles.themes}>
                {pageContext.themes.map((theme, index) => (
                  <Link
                    key={index}
                    className={styles.themeCard}
                    to={`/memes/${convertThemeToSlug(theme)}`}
                  >
                    {theme}
                  </Link>
                ))}
              </div>
            )}

            <p className={styles.date}>
              {`${new Date(pageContext.date).toLocaleString("default", {
                month: "long",
              })}, ${new Date(pageContext.date).getDate()} ${new Date(
                pageContext.date
              ).getFullYear()}`}
            </p>
            <p className={styles.date}>© Dagobert Renouf</p>

            <TweetCard
              date={pageContext.date}
              id={pageContext.sourceTweet
                .split("/")
                [pageContext.sourceTweet.split("/").length - 1].trim()}
              performance={pageContext.performance}
            />
          </div>
        </div>
        <div className={styles.relatedMemes}>
          <div className={styles.wrapper}>
            <div className={styles.browseText}>
              <p>Related Memes</p>
            </div>
            {typeof window !== "undefined" && (
              <Plock nColumns={breakpoints} gap="2rem">
                {pageContext.relatedMemes.map((meme, index) => (
                  <MemeCard
                    key={index}
                    id={meme.id}
                    type={meme.type}
                    poster={meme.poster}
                    media={meme.media}
                    date={meme.date}
                    themes={meme.themes}
                    themeColor={meme.themeColor}
                    setLastViewedMeme={() =>
                      sessionStorage.removeItem("lastViewedMeme")
                    }
                  />
                ))}
              </Plock>
            )}
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default MemePage
