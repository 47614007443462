// extracted by mini-css-extract-plugin
export var browseText = "MemePage-module--browseText--Dv9xf";
export var caption = "MemePage-module--caption--Bzzbv";
export var container = "MemePage-module--container--IiYkF";
export var date = "MemePage-module--date--tVNEK";
export var main = "MemePage-module--main--LjLIO";
export var media = "MemePage-module--media--m0FD3";
export var relatedMemes = "MemePage-module--relatedMemes--llC4w";
export var themeCard = "MemePage-module--themeCard--Ziwb7";
export var themes = "MemePage-module--themes--H8+9N";
export var tweet = "MemePage-module--tweet--RLBBu";
export var wrapper = "MemePage-module--wrapper--5DDxH";